import {
  AppBar,
  Box,
  Button,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import logo from "../Assets/Final-Logo2-removebg-preview.png";
import { Link } from "react-router-dom";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

function Header() {
  const logoSize = useMediaQuery("(max-width: 1060px)");

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null); // Services menu
  const [anchorElRooftop, setAnchorElRooftop] = useState(null); // Rooftop sub-menu
  const [menu, setMenu] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleOpenServices = (event) => {
    setAnchorEl(event.currentTarget); // Open Services menu
  };

  const handleCloseServices = () => {
    setAnchorEl(null); // Close Services menu
  };

  const handleOpenRooftop = (event) => {
    setAnchorElRooftop(event.currentTarget); // Open Rooftop sub-menu
  };

  const handleCloseRooftop = () => {
    setAnchorElRooftop(null); // Close Rooftop sub-menu
  };

  useEffect(() => {
    const handleWindowFocus = () => {
      if (open) {
        handleCloseServices();
      }
    };

    window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, [open]);

  return (
    <div>
      <AppBar
        position="relative"
        sx={{
          padding: "0 100px",
          bgcolor: "#ebf5ef",
          "@media (max-width:1261px)": { p: "0" },
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            p: "10px 0",
            alignItems: "end",
          }}
        >
          <Box>
            <Link to="/">
              <img
                src={logo}
                width={logoSize ? "120px" : "auto"}
                height={logoSize ? "auto" : "70px"}
                alt=""
              />
            </Link>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "35px",
              alignItems: "center",
              color: "black",
              "@media (max-width: 1000px)": { display: "none" },
              "@media (max-width: 1024px)": { gap: "20px" },
            }}
          >
            {/* <Link
              to="/aboutus"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "18px", fontWeight: "700", cursor: "pointer" }}
              >
                About Us
              </Typography>
            </Link> */}
            <Box
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "18px", fontWeight: "700" }}
                onClick={handleOpenServices}
              >
                Services
              </Typography>
              <KeyboardArrowDownIcon />
              {anchorEl && ( // Services menu
                <Menu
                  id="services-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseServices}
                  sx={{ position: "absolute", top: '5%' }}
                >
                  <Link
                    to="/Utility"
                    style={{
                      textDecoration: "none",
                      color: "inherit",
                      cursor: "pointer",
                    }}
                    onClick={handleCloseServices}
                  >
                    <MenuItem>UTILITY SERVICES</MenuItem>
                  </Link>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    onClick={handleOpenRooftop}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <MenuItem>ROOF TOP</MenuItem> <ArrowForwardIosIcon />
                    </Box>
                  </Box>
                  {/* Trigger Rooftop sub-menu */}
                  {/* Add more services here */}
                </Menu>
              )}
              {anchorElRooftop && ( // Rooftop sub-menu
                <Menu
                  id="rooftop-menu"
                  anchorEl={anchorElRooftop}
                  open={Boolean(anchorElRooftop)}
                  onClose={handleCloseRooftop}
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  transformOrigin={{ vertical: "top", horizontal: "left" }}
                  sx={{ position: "absolute" }} // Adjust positioning here
                >
                  <Link
                    to="/onGrid"
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={() => {
                      handleCloseServices();
                      handleCloseRooftop();
                    }}
                  >
                    <MenuItem>ON GRID</MenuItem>
                  </Link>
                  <Link
                    to="/offGrid"
                    style={{ textDecoration: "none", color: "inherit" }}
                    onClick={() => {
                      handleCloseServices();
                      handleCloseRooftop();
                    }}
                  >
                    <MenuItem>OFF GRID</MenuItem>
                  </Link>
                </Menu>
              )}
            </Box>
            <Link
              to="/products"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "18px", fontWeight: "700", cursor: "pointer" }}
              >
                Products
              </Typography>
            </Link>
            <Link
              to="/blog"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "18px", fontWeight: "700", cursor: "pointer" }}
              >
                Blog
              </Typography>
            </Link>
            <Link
              to="/gallery"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "18px", fontWeight: "700", cursor: "pointer" }}
              >
                Gallery
              </Typography>
            </Link>
            <Link
              to="/contactUs"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Typography
                variant="p"
                sx={{ fontSize: "18px", fontWeight: "700", cursor: "pointer" }}
              >
                Contact Us
              </Typography>
            </Link>
            <Link
              to="/contactUs"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                sx={{
                  background:
                    "radial-gradient(circle, rgba(108,214,115,1) 0%, rgba(72,70,229,1) 100%)",
                  padding: "10px 20px",
                  borderRadius: "30px",
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "600",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(0,255,42,1) 17%, rgba(21,21,236,1) 100%)",
                  },
                }}
              >
                GET A QUOTE
              </Button>
            </Link>
          </Box>
          <Box
            sx={{
              "@media (min-width: 1001px)": { display: "none" },
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Link
              to="/contactUs"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <Button
                sx={{
                  background:
                    "radial-gradient(circle, rgba(108,214,115,1) 0%, rgba(72,70,229,1) 100%)",
                  padding: "8px 15px",
                  borderRadius: "30px",
                  color: "#fff",
                  fontSize: "14px",
                  fontWeight: "600",
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(0,255,42,1) 17%, rgba(21,21,236,1) 100%)",
                  },
                }}
              >
                GET A QUOTE
              </Button>
            </Link>
            <MenuIcon
              sx={{ color: "black" }}
              onClick={(e) => {
                setMenu(!menu);
              }}
            />
          </Box>
        </Toolbar>
        {menu && (
          <Box
            position="absolute"
            sx={{
              width: "100%",
              top: "100%",
              zIndex: "9999",
              bgcolor: "#d9f1d7",
              color: "#666666",
            }}
          >
            <List>
              {/* <Link
                to="/aboutus"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemButton>
                  <ListItemText primary="About Us" />
                </ListItemButton>
              </Link> */}

              <ListItemButton onClick={handleClick}>
                <ListItemText primary="Services" />
                {open ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{
                      pl: "45px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Link
                      to="/utility"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <ListItemText primary="Utility Skills" />
                    </Link>
                    <ListItemButton
                      onClick={handleClick}
                      sx={{ width: "100%" }}
                    >
                      <ListItemText primary="Rooftop" sx={{ ml: "-16px" }} />
                      {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </ListItemButton>
                  <Collapse in={open} timeout="auto" unmountOnExit>
                    <ListItemButton
                      sx={{
                        pl: "75px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Link
                        to="/onGrid"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <ListItemText primary="On Grid" />
                      </Link>
                      <Link
                        to="/offGrid"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <ListItemText primary="Off Grid" />
                      </Link>
                    </ListItemButton>
                  </Collapse>
                </List>
              </Collapse>
              <Link
                to="/products"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemButton>
                  <ListItemText primary="Products" />
                </ListItemButton>
              </Link>
              <ListItemButton>
                <ListItemText primary="Blog" />
              </ListItemButton>
              <Link
                to="/gallery"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemButton>
                  <ListItemText primary="Gallery" />
                </ListItemButton>
              </Link>
              <Link
                to="/contactUs"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ListItemButton>
                  <ListItemText primary="Contact Us" />
                </ListItemButton>
              </Link>
            </List>
          </Box>
        )}
      </AppBar>
    </div>
  );
}

export default Header;
